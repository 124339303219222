import generalApi from '@/api/general-api'
import i18n from '@/libs/i18n'
import eventsApi from '../../api/events-api'

const state = {
  event: {},
  items: [],
  itemsTasks: [],
  totalItems: 0,
  itemsLogs: [],
  totalItemsLogs: 0,
}

const mutations = {
  setEvent(state, val) {
    state.event = val
  },
  setItems(state, val) {
    state.items = val
  },
  setItemsTasks(state, val) {
    state.itemsTasks = val
  },
  setItemsLogs(state, val) {
    state.itemsLogs = val
  },
  setTotalItemsLogs(state, val) {
    state.totalItemsLogs = val
  },
}

const actions = {
  async getEvent({ commit }, id) {
    commit('app/loading', true, { root: true })
    await eventsApi.getEvent(id).then(
      response => {
        commit('setEvent', response.data.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorGet', { nombre: i18n.t('Evento') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getList({ commit }) {
    await generalApi.types('event').then(
      response => {
        commit('setItems', response.data)
        const array = []
        response.data.forEach(val => {
          if (val.show_task === 1) {
            array.push(val)
          }
        })
        commit('setItemsTasks', array)
      },
      () => {
        commit(
          'notifications',
          {
            title: i18n.t('ErrorListado', { nombre: i18n.t('Eventos') }),
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getListMaintenance(
    { commit },
    {
      company = '',
      client,
      status = '',
      event_type = '',
      page,
      per_page,
      date_ini = '',
      date_fin = '',
      search = '',
      user_id = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    await eventsApi
      .logAll(
        company,
        client,
        status,
        event_type,
        page,
        per_page,
        date_ini,
        date_fin,
        search,
        user_id,
      )
      .then(
        (response) => {
          commit("setItemsLogs", response.data.data);

          commit("setTotalItemsLogs", response.data.meta.total[1]);
          commit("app/loading", false, { root: true });
        },
        error => {
          commit('app/loading', false, { root: true })
          commit(
            'notifications',
            { title: i18n.t('ErrorListado', { nombre: i18n.t('Eventos') }), variant: 'danger' },
            { root: true },
          )
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  edit({ commit }, { id, event }) {
    commit('app/loading', true, { root: true })
    eventsApi.edit(id, event).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessUpdateMaculino', { nombre: i18n.t('Evento') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Evento') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    eventsApi.delete(id).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessDelete', { nombre: i18n.t('Evento') }), variant: 'success' },
          { root: true },
        )
        location.reload()
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorDelete', { nombre: i18n.t('Evento') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getListLogs({ commit }, {
    id = '', page, per_page, search = '', user,
  }) {
    await eventsApi.log(id, page, per_page, search, null, user)
    .then(
      response => {
        commit('setItemsLogs', response.data.data)
        commit('setTotalItemsLogs', response.data.meta.total[1])
      },
      error => {
        commit(
          'notifications',
          { title: i18n.t('ErrorListado', { nombre: i18n.t('Eventos') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
}

const getters = {
  getEvent: state => state.event,
  getItems: state => state.items,
  getItemsTask: state => state.itemsTasks,
  getItemsLogs: state => state.itemsLogs,
  getTotalItemsLogs: state => state.totalItemsLogs,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

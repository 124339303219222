import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/towns'


export default {
  getList(province_id = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, { province_id })
  },
}

import projectsApi from "@/api/projects-api";
import router from "@/router";
import i18n from "@/libs/i18n";
import usersApi from "../../api/users-api";
import generalApi from "../../api/general-api";

const state = {
  user: {},
  items: [],
  totalItems: 0,
  selectUsers: [],
  selectResponsablesTask: [],
  selectUsersTask: [],
  roles: [],
};

const mutations = {
  setUser(state, val) {
    state.user = val;
  },
  setItems(state, val) {
    state.items = val;
  },
  setTotalItems(state, val) {
    state.totalItems = val;
  },
  setSelectUsers(state, val) {
    state.selectUsers = val;
  },
  setSelectUsersTask(state, val) {
    state.selectUsersTask = val;
  },
  setSelectResponsablesTask(state, val) {
    state.selectResponsablesTask = val;
  },
  setRoles(state, val) {
    state.roles = val;
  },
};

const actions = {
  async selectUsersTask(
    { commit },
    { company = "", client = "", subclient = "", roles = [], search = "" }
  ) {
    await usersApi
      .list(company, client, subclient, 1, 999999, search, roles)
      .then(
        (response) => {
          commit("setSelectUsersTask", response.data.data);
        },
        () => {
          commit(
            "notifications",
            {
              title: i18n.t("ErrorListado", { nombre: i18n.t("Usuarios") }),
              variant: "danger",
            },
            { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
      );
  },
  async selectResponsablesTask(
    { commit },
    { company = "", client = "", subclient = "", roles = [], search = "" }
  ) {
    await usersApi
      .list(company, client, subclient, 1, 999999, search, roles)
      .then(
        (response) => {
          commit("setSelectResponsablesTask", response.data.data);
        },
        () => {
          commit(
            "notifications",
            {
              title: i18n.t("ErrorListado", { nombre: i18n.t("Usuarios") }),
              variant: "danger",
            },
            { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
      );
  },
  async selectUsers({ commit }, { company = "", client = "", subclient = "" }) {
    await usersApi.list(company, client, subclient, 1, 999999, "").then(
      (response) => {
        commit("setSelectUsers", response.data.data);
      },
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("ErrorListado", { nombre: i18n.t("Usuarios") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  edit({ commit }, { id, user }) {
    commit("app/loading", true, { root: true });
    usersApi.edit(id, user).then(
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessUpdateMaculino", {
              nombre: i18n.t("Usuario"),
            }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        commit("app/loading", false, { root: true });
      },
      (error) => {
        commit("app/loading", false, { root: true });
        commit("setShowNotifications", true, { root: true });
        if (
          error.response.data.error &&
          error.response.data.error === "Username is reepeat"
        ) {
          commit(
            "notifications",
            {
              title: "El nombre de usuario debe ser único por cliente",
              variant: "danger",
            },
            { root: true }
          );
        } else {
          commit(
            "notifications",
            {
              title: i18n.t("ErrorUpdate", { nombre: i18n.t("Usuario") }),
              variant: "danger",
            },
            { root: true }
          );
        }
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  resetPassword({ commit }, { id, oldPassword, newPassword }) {
    commit("app/loading", true, { root: true });
    usersApi.resetPassword(id, oldPassword, newPassword).then(
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessUpdateMaculino", {
              nombre: i18n.t("Usuario"),
            }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        commit("app/loading", false, { root: true });
      },
      (error) => {
        commit("app/loading", false, { root: true });
        commit("setShowNotifications", true, { root: true });
        if (
          error.response.data.error &&
          error.response.data.error === "Old Password incorrect"
        ) {
          commit(
            "notifications",
            { title: "Contraseña actual incorrecta", variant: "danger" },
            { root: true }
          );
        } else {
          commit(
            "notifications",
            {
              title: i18n.t("ErrorUpdate", { nombre: i18n.t("Usuario") }),
              variant: "danger",
            },
            { root: true }
          );
        }
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async getUser({ commit }, id) {
    commit("app/loading", true, { root: true });
    await usersApi.getUser(id).then(
      (response) => {
        commit("setUser", response.data.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorGet", { nombre: i18n.t("Usuario") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  getListUsers(
    { commit },
    {
      company = "",
      client = "",
      subclient = "",
      page,
      per_page,
      search = "",
      roles = [],
    }
  ) {
    commit("app/loading", true, { root: true });

    usersApi
      .list(company, client, subclient, page, per_page, search, roles)
      .then(
        (response) => {
          commit("setItems", response.data.data);
          commit("setTotalItems", response.data.meta.total[1]);
          commit("app/loading", false, { root: true });
        },
        () => {
          commit("app/loading", false, { root: true });
          commit(
            "notifications",
            {
              title: i18n.t("ErrorListado", { nombre: i18n.t("Usuarios") }),
              variant: "danger",
            },
            { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
      );
  },
  delete({ commit }, id) {
    commit("app/loading", true, { root: true });
    usersApi.delete(id).then(
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("ErrorListado", { nombre: i18n.t("Usuario") }),
            variant: "success",
          },
          { root: true }
        );
        location.reload();
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("SuccessDelete", { nombre: i18n.t("Usuario") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  app_access({ commit }, id) {
    commit("app/loading", true, { root: true });
    usersApi.app_access(id).then(
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessUpdate", { nombre: i18n.t("Usuario") }),
            variant: "success",
          },
          { root: true }
        );
        // location.reload();
        commit("app/loading", false, { root: true });
        commit("setShowNotifications", true, { root: true });

      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("SuccessUpdateMaculino", {
              nombre: i18n.t("Usuario"),
            }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  create({ commit }, { user }) {
    commit("app/loading", true, { root: true });
    usersApi.create(user).then(
      (response) => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessCreate", { nombre: i18n.t("Usuario") }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        //  router.push({ name: 'editUser', params: { id: response.data.data.id } })
        router.push({ name: "users" });
        commit("app/loading", false, { root: true });
      },
      (error) => {
        commit("app/loading", false, { root: true });
        commit("setShowNotifications", true, { root: true });
        if (
          error.response.data.error &&
          error.response.data.error === "Username is reepeat"
        ) {
          commit(
            "notifications",
            {
              title: "El nombre de usuario debe ser único por cliente",
              variant: "danger",
            },
            { root: true }
          );
        } else {
          commit(
            "notifications",
            {
              title: i18n.t("ErrorCreate", { nombre: i18n.t("Usuario") }),
              variant: "danger",
            },
            { root: true }
          );
        }
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  contact_person({ commit }, { user }) {
    commit("app/loading", true, { root: true });
    return usersApi.contact_person(user).then(
      (response) => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessCreate", { nombre: i18n.t("PersonaContacto") }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        //  router.push({ name: 'editUser', params: { id: response.data.data.id } })
        commit("app/loading", false, { root: true });
        return response;
      },
      (error) => {
        commit("app/loading", false, { root: true });
        commit("setShowNotifications", true, { root: true });
        if (
          error.response.data.error &&
          error.response.data.error === "Username is reepeat"
        ) {
          commit(
            "notifications",
            {
              title: "El nombre de usuario debe ser único por cliente",
              variant: "danger",
            },
            { root: true }
          );
        } else {
          commit(
            "notifications",
            {
              title: i18n.t("ErrorCreate", { nombre: i18n.t("Usuario") }),
              variant: "danger",
            },
            { root: true }
          );
        }
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  getRoles({ commit }, all = false) {
    commit("app/loading", true, { root: true });
    generalApi.roles().then(
      (response) => {
        if (!all) {
          const roles = [];
          response.data.forEach((element) => {
            if (
              element.name != "admin_empresa" &&
              element.name != "super_admin"
            ) {
              roles.push(element);
            }
          });
          commit("setRoles", roles);
        } else {
          commit("setRoles", response.data);
        }
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorCarga", { nombre: i18n.t("Roles") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
};

const getters = {
  getUser: (state) => state.user,
  getItems: (state) => state.items,
  getTotalItems: (state) => state.totalItems,
  getSelectUsers: (state) => state.selectUsers,
  getSelectResponsablesTask: (state) => state.selectResponsablesTask,
  getSelectUsersTask: (state) => state.selectUsersTask,
  getRoles: (state) => state.roles,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import externalEventsApi from '@/api/external-events-api'
import i18n from '@/libs/i18n'
import DownloadService from "@/shared/services/download-service";

const state = {
  items: [],
  totalItems: 0,
}


const mutations = {
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async createEvent({ commit }, event) {
    commit('app/loading', true, { root: true })
    await externalEventsApi.create(event).then(
      response => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCreate', { nombre: i18n.t('ExternalAction') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCreate', { nombre: i18n.t('ExternalAction') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getListAsset({ commit }, {
    asset_id, page, per_page, search = ''
  }) {
    await externalEventsApi.getListAsset(asset_id, page, per_page, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('ExternalAction')}), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  downloadZipDocuments({ commit }, id) {
    externalEventsApi.downloadZip(id).then(
      response => {
        DownloadService.resolveAndDownloadBlob(response.data, 'Documents', 'zip')
      },
      () => {
        commit(
          'notifications',
          { title: i18n.t('ErrorDownload'), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  }
}

const getters = {
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import i18n from '@/libs/i18n'
import provincesApi from '@/api/provinces-api'

const state = {
  items: [],
}

const mutations = {
  setItems(state, val) {
    state.items = val
  },
}

const actions = {
  async getSelectProvinces({ commit }) {    
    await provincesApi.getList(1, 9999999, '')
      .then(
        response => {

          commit('setItems', response.data.data)
        },
        () => {
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Provinces') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getItems: state => state.items,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import i18n from '@/libs/i18n'
import categoriesApi from '../../api/categories-api'
import router from '../../router'
import locationsApi from "@/api/locations-api";

const state = {
  category: {},
  items: [],
  totalItems: 0,
}

const mutations = {
  setCategory(state, val) {
    state.category = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async getCategory({ commit }, id) {
    commit('app/loading', true, { root: true })
    await categoriesApi.getCategory(id)
      .then(
        response => {
          commit('setCategory', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorGet', { nombre: i18n.t('Categoría') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getList({ commit }, {
    parent_id = '', page, per_page, search = '',
  }) {
    commit('app/loading', true, { root: true })
      categoriesApi.getList(parent_id, page, per_page, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Categorías') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, category }) {
    commit('app/loading', true, { root: true })
      categoriesApi.edit(id, category).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessUpdate', { nombre: i18n.t('Categoría') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Categoría') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  create({ commit }, { category }) {
    commit('app/loading', true, { root: true })
      categoriesApi.create(category).then(
      response => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCreate', { nombre: i18n.t('Categoría') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({ name: 'categories' })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCreate', { nombre: i18n.t('Categoría') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    categoriesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessDelete', { nombre: i18n.t('Categoría') }), variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorDelete', { nombre: i18n.t('Categoría') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getCategory: state => state.category,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/provinces'


export default {
  getList(page, per_page, search) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {      
      page,
      per_page,
      search,
    })
  },
}

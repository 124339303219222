import generalApi from '@/api/general-api'
import i18n from '@/libs/i18n'
import tasksApi from '../../api/tasks-api'
import router from '../../router'

const state = {
  task: {},
  items: [],
  itemsAll: [],
  itemsAsset: [],
  itemsAssetTotal: 0,
  selectStatus: [],
  totalItems: 0,
}

const mutations = {
  setTask(state, val) {
    state.task = val
  },
  setItems(state, val) {
    state.items = val
  },
  setItemsAll(state, val) {
    state.itemsAll = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
  setItemsAsset(state, val) {
    state.itemsAsset = val
  },
}

const actions = {
  async getTask({ commit }, id) {
    commit('app/loading', true, { root: true })
    await tasksApi.getTask(id).then(
      response => {
        commit('setTask', response.data.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: this.$i18n.t('ErrorGet', { nombre: this.$i18n.t('Tarea') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  getListTasks(
    { commit },
    {
      client = '',
      page,
      per_page,
      search = '',
      date_ini = '',
      date_fin = '',
      priority = '',
      status = '',
      orderBy = '',
      user_id = '',
      event_id = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    tasksApi
      .getList(
        client,
        page,
        per_page,
        search,
        date_ini,
        date_fin,
        priority,
        status,
        orderBy,
        user_id,
        event_id,
      )
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit(
            'notifications',
            {
              title: i18n.t('ErrorListado', { nombre: i18n.t('Tareas') }),
              variant: 'danger',
            },
            { root: true },
          )
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListTasksAsset(
    { commit },
    {
      id,
      page,
      per_page,
      search = '',
      date_ini = '',
      date_fin = '',
      priority = '',
      status = '',
      orderBy = '',
      user = '',
      event_id = '',
    },
  ) {
    tasksApi
      .getListAsset({
        id,
        page,
        per_page,
        search,
        date_ini,
        date_fin,
        priority,
        status,
        orderBy,
        user,
        event_id,
      })
      .then(
        response => {
          commit('setItemsAsset', response.data.data)
          commit('setItemsAssetTotal', response.data.meta.total[1])
        },
        () => {
          commit(
            'notifications',
            {
              title: i18n.t('ErrorListado', { nombre: i18n.t('Tareas') }),
              variant: 'danger',
            },
            { root: true },
          )
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListAllTasks(
    { commit },
    {
      client = '',
      page,
      per_page,
      search = '',
      date_ini = '',
      date_fin = '',
      priority = '',
      status = '',
      orderBy = '',
      user = '',
      event_id = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    tasksApi
      .getList(
        client,
        1,
        10000,
        search,
        date_ini,
        date_fin,
        priority,
        status,
        orderBy,
        user,
        event_id,
      )

      .then(
        response => {
          commit('setItemsAll', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit(
            'notifications',
            {
              title: i18n.t('ErrorListado', { nombre: i18n.t('Tareas') }),
              variant: 'danger',
            },
            { root: true },
          )
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, task }) {
    commit('app/loading', true, { root: true })
    tasksApi.edit(id, task).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessUpdate', { nombre: i18n.t('Tarea') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
        router.push({
          name: 'viewTask',
          params: { id },
        })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Tarea') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  create({ commit }, { task }) {
    commit('app/loading', true, { root: true })
    tasksApi.create(task).then(
      response => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCreate', { nombre: i18n.t('Tarea') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        /*  router.push({
          name: "tasks",
        }); */

        commit('app/loading', false, { root: true })
        router.push({
          name: 'viewTask',
          params: { id: response.data.data.id },
        })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCreate', { nombre: i18n.t('Tarea') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getSelectStatus({ commit }) {
    await generalApi.status('task').then(
      response => {
        commit('setSelectStatus', response.data)
      },
      () => {
        commit(
          'notifications',
          { title: i18n.t('ErrorListado', { nombre: i18n.t('Estados') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    tasksApi.delete(id).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessDelete', { nombre: i18n.t('Tarea') }), variant: 'success' },
          { root: true },
        )
        location.reload()
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorDelete', { nombre: i18n.t('Tarea') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
}

const getters = {
  getTask: state => state.task,
  getItems: state => state.items,
  getItemsAll: state => state.itemsAll,
  getItemsAsset: state => state.itemsAsset,
  getItemsAssetTotal: state => state.itemsAssetTotal,
  getTotalItems: state => state.totalItems,
  getSelectStatus: state => state.selectStatus,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import i18n from '@/libs/i18n'
import townsApi from '@/api/towns-api'

const state = {
  items: [],
}

const mutations = {
  setItems(state, val) {
    state.items = val
  },
}

const actions = {
  async getSelectTowns({ commit },{ province_id = '' } = {}) {
    await townsApi.getList(province_id)
      .then(
        response => {
          commit('setItems', response.data.data)
        },
        () => {
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Towns') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getItems: state => state.items,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/products'

export default {
  getProduct(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(company = '', page, per_page, search = '', group = '', client='') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_company: company,
      page,
      per_page,
      search,
      id_group: group,
      id_client: client
    })
  },
  getListDashboard(company = '', page, per_page, search = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list/dashboard`, {
      id_company: company,
      page,
      per_page,
      search,
    })
  },
  edit(id, product) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, product);
  },
  create(product) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, product);
  },
}

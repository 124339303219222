import clientsApi from '@/api/clients-api'
import router from '@/router'
import i18n from '@/libs/i18n'

const state = {
  client: {},
  currentClient: null,
  showModalClients: false,
  listClients: [],
  selectClients: [],
  items: [],
  totalItems: 0,
  errors: '',
}

const mutations = {
  recoverCurrentClient(state) {
    const recoveredCurrentClient = JSON.parse(
      localStorage.getItem('currentClient'),
    )
    if (!recoveredCurrentClient) {
      state.currentClient = null
    } else {
      state.currentClient = recoveredCurrentClient
    }
  },
  setShowModalClients(state, val) {
    state.showModalClients = val
  },
  setlistClients(state, val) {
    state.listClients = val
  },
  setErrors(state, val) {
    state.errors = val
  },
  setSelectClients(state, val) {
    state.selectClients = val
  },
  setCurrentClient(state, val) {
    state.currentClient = val
    localStorage.setItem('currentClient', JSON.stringify(state.currentClient))
    location.reload()
  },
  removeCurrentClient(state) {
    state.currentClient = null
    localStorage.removeItem('currentClient')
  },
  setItems(state, val) {
    state.items = val
  },
  setClient(state, val) {
    state.client = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async selectClients({ commit }, { company, client }) {
    await clientsApi.list(company).then(
      response => {
        if (client != null && client != undefined) {
          let clients = []
          response.data.data.forEach(element => {
            if (element.id == client.id) {
              clients.push(element)
            }
          })

          if (clients.length == 0) {
            clients = response.data.data
          }

          commit('setSelectClients', clients)
        } else {
          commit('setSelectClients', response.data.data)
        }
      },
      () => {
        commit(
          'notifications',
          { title: i18n.t('ErrorListado', { nombre: i18n.t('Clientes') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async searchClients(
    { commit },
    {
      company = '', page, per_page, search = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    await clientsApi.list(company, page, per_page, search).then(
      response => {
        commit('setlistClients', response.data.data)
        commit('setItems', response.data.data)
        commit('setTotalItems', response.data.meta.total[1])
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorListado', { nombre: i18n.t('Clientes') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getListClients(
    { commit },
    {
      company = '', page, per_page, search = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    await clientsApi.list(company, page, per_page, search).then(
      response => {
        commit('setlistClients', response.data.data)
        commit('setItems', response.data.data)
        commit('setTotalItems', response.data.meta.total[1])
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorListado', { nombre: i18n.t('Clientes') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getClient({ commit }, id) {
    commit('app/loading', true, { root: true })
    await clientsApi.getClient(id).then(
      response => {
        commit('setClient', response.data.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorGet', { nombre: i18n.t('Cliente') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  edit({ commit }, { id, client }) {
    commit('app/loading', true, { root: true })
    clientsApi.edit(id, client).then(
      () => {
        commit(
          'notifications',
          { title: i18n.t('SuccessUpdateMaculino', { nombre: i18n.t('Cliente') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Cliente') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  create({ commit }, { client }) {
    commit('app/loading', true, { root: true })
    clientsApi.create(client).then(
      response => {
        commit(
          'notifications',
          { title: i18n.t('SuccessCreate', { nombre: i18n.t('Cliente') }), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({
          name: 'editClient',
          params: { id: response.data.data.id },
        })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('ErrorCreate', { nombre: i18n.t('Cliente') }), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },

  notifymanual({ commit }, { notify }) {
    commit('app/loading', true, { root: true })
    clientsApi.notifymanual(notify).then(
      response => {
        commit(
          'notifications',
          { title: i18n.t('NotificationSendSuccess'), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({
          name: 'dashboard',
        })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('NotificationSendError'), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  notifyauto({ commit }, { notify }) {
    commit('app/loading', true, { root: true })
    clientsApi.notifyauto(notify).then(
      response => {
        commit(
          'notifications',
          { title: i18n.t('NotificationSaveSuccess'), variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({
          name: 'dashboard',
        })
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: i18n.t('NotificationSaveError'), variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  import({ commit }, { excel }) {
    // no traducido porque es para el super_admin
    commit('app/loading', true, { root: true })
    clientsApi.import(excel)
      .then(
        response => {
          commit('notifications', { title: 'Cliente importado correctamente.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          // location.reload()
        },
        error => {
          commit('app/loading', false, { root: true })
          if (error.response.data.error && error.response.data.error === 'codCliERP unique') {
            commit('notifications', { title: 'El código del cliente debe ser único', variant: 'danger' }, { root: true })
          } else if (error.response.data.error) {
            commit('setErrors', JSON.stringify(error.response.data.error))
            commit('notifications', { title: 'Error al importar cliente.', variant: 'danger' }, { root: true })
          } else {
            commit('notifications', { title: 'Error al importar cliente.', variant: 'danger' }, { root: true })
          }
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getCurrentClient: state => state.currentClient,
  getSelectClients: state => state.selectClients,
  getListClients: state => state.listClients,
  getListClientsFrecuentes: state => state.listClientsFrecuentes,
  getShowModalClients: state => state.showModalClients,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getClient: state => state.client,
  getError: state => state.errors,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

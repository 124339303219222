import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/forms'

export default {
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  edit(id, form) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, form)
  },
  create(form) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, form)
  },
  clone(id, name) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/clone/${id}`,{
      name
    })
  },
  //Agisnamos category a formulario. También la URL del iframe del formulario en caso de ser externo al objeto category,
  assignForm(form_id = '', category_id, event, url_form = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/assign`, {
      form_id,
      category_id,
      event,
      url_form,
    })
  },
  getForm(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(page, per_page, search, type = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      type,
    })
  },
  getListEdit(page, per_page, search) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list/edit`, {
      page,
      per_page,
      search,
    })
  },
}

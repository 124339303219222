import seriesApi from '@/api/series-api'
import router from '@/router'

const state = {
  serie: {},
  items: [],
  totalItems: 0,
  selectSeries: [],
}

const mutations = {
  setSerie(state, val) {
    state.serie = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectSeries(state, val) {
    state.selectSeries = val
  },
}

const actions = {
  async selectSeries({ commit }, { client_id }) {
    commit('app/loading', true, { root: true })
    seriesApi.list(client_id, '', 99999999, 1)
      .then(
        response => {
          commit('setSelectSeries', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('notifications', { title: 'Error al obtener el listado de series.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListSeries(
    { commit },
    {
      client, page, per_page, search = '',
    },
  ) {
    commit('app/loading', true, { root: true })
    await seriesApi.list(client, search, per_page, page).then(
      response => {
        commit('setItems', response.data.data)
        commit('setTotalItems', response.data.meta.total[1])
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          {
            title: 'Error al obtener el listado de series.',
            variant: 'danger',
          },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  async getSerie({ commit }, id) {
    commit('app/loading', true, { root: true })
    await seriesApi.getSerie(id).then(
      response => {
        commit('setSerie', response.data.data)
        commit('app/loading', false, { root: true })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: 'Error al obtener la serie.', variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  create({ commit }, { serie }) {
    commit('app/loading', true, { root: true })
    seriesApi.create(serie).then(
      () => {
        commit(
          'notifications',
          { title: 'Serie creada con éxito.', variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        router.push({ name: 'series' })
        commit('app/loading', false, { root: true })
      },
      error => {
        commit('app/loading', false, { root: true })
        if (error.response.data.error && error.response.data.error === 'Serie reepeat') {
          commit('notifications', { title: 'Error al crear la serie, el nombre debe ser único', variant: 'danger' }, { root: true })
        } else {
          commit(
            'notifications',
            { title: 'Error al crear la serie.', variant: 'danger' },
            { root: true },
          )
        }
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    seriesApi.delete(id).then(
      () => {
        commit(
          'notifications',
          { title: 'Serie eliminada con éxito.', variant: 'success' },
          { root: true },
        )
        location.reload()
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: 'Error al eliminar la serie.', variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
  edit({ commit }, { id, serie }) {
    commit('app/loading', true, { root: true })
    seriesApi.edit(id, serie).then(
      () => {
        commit(
          'notifications',
          { title: 'Serie actualizada con éxito.', variant: 'success' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
        commit('app/loading', false, { root: true })
        router.push({ name: 'series' })
      },
      () => {
        commit('app/loading', false, { root: true })
        commit(
          'notifications',
          { title: 'Error al actualizar la serie.', variant: 'danger' },
          { root: true },
        )
        commit('setShowNotifications', true, { root: true })
      },
    )
  },
}

const getters = {
  getSerie: state => state.serie,
  getSelectSeries: state => state.selectSeries,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

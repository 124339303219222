import formsApi from '@/api/forms-api'
import generalApi from '@/api/general-api'
import router from '@/router'
import i18n from '@/libs/i18n'

const state = {
  form: {},
  items: [],
  totalItems: 0,
  selectElements: [],
  itemsEdit: [],
  totalItemsEdit: 0,
}

const mutations = {
  setForm(state, val) {
    state.form = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setItemsEdit(state, val) {
    state.itemsEdit = val
  },
  setTotalItemsEdit(state, val) {
    state.totalItemsEdit = val
  },
  setSelectElements(state, val) {
    state.selectElements = val
  },
}

const actions = {
  async getForm({ commit }, id) {
    commit('app/loading', true, { root: true })
    await formsApi.getForm(id)
      .then(
        response => {
          commit('setForm', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorGet', { nombre: i18n.t('Formulario') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getList({ commit }, {
    page, per_page, search = '', type = '',
  }) {
    commit('app/loading', true, { root: true })
    await formsApi.getList(page, per_page, search, type)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Formularios') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListEdit({ commit }, {
    page, per_page, search = '',
  }) {
    commit('app/loading', true, { root: true })
    formsApi.getListEdit(page, per_page, search)
      .then(
        response => {
          commit('setItemsEdit', response.data.data)
          commit('setTotalItemsEdit', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Formularios') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, form }) {
    commit('app/loading', true, { root: true })
    formsApi.edit(id, form)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessUpdateMaculino', { nombre: i18n.t('Formulario') }), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          // commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Formulario') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { form }) {
    commit('app/loading', true, { root: true })
    formsApi.create(form)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessCreateMasculino', { nombre: i18n.t('Formulario') }), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'forms' })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorCreate', { nombre: i18n.t('Formulario') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectElements({ commit }) {
    await generalApi.types('element')
      .then(
        response => {
          commit('setSelectElements', response.data)
        },
        () => {
          commit('notifications', { title: i18n.t('ErrorCarga', { nombre: i18n.t('Elementos') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async assign({ commit }, {
    form_id = '', category_id, event, url_form = '',
  }) {
      console.log(form_id, category_id, event, url_form)
    commit('app/loading', true, { root: true })
    await formsApi.assignForm(form_id, category_id, event, url_form)
      .then(
        response => {
          commit('notifications', { title: i18n.t('SuccessAssign', { nombre: i18n.t('Formulario') }), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('notifications', { title: i18n.t('ErrorAssign', { nombre: i18n.t('Formulario') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    formsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessDeleteMasculino', { nombre: i18n.t('Formulario') }), variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('ErrorDelete', { nombre: i18n.t('Formulario') }), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  clone({ commit }, { id, name }) {
    commit('app/loading', true, { root: true })
    formsApi.clone(id, name)
      .then(() => {
        commit('notifications', { title: i18n.t('SuccessCloned', { nombre: i18n.t('Formulario') }), variant: 'success' }, { root: true })
        commit('setShowNotifications', true, { root: true })
        location.reload()
      })
      .catch(() => {
        commit('app/loading', false, { root: true })
        commit('notifications', { title: i18n.t('ErrorCloned', { nombre: i18n.t('Formulario') }), variant: 'danger' }, { root: true })
        commit('setShowNotifications', true, { root: true })
      })
  }
}

const getters = {
  getForm: state => state.form,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getItemsEdit: state => state.itemsEdit,
  getTotalItemsEdit: state => state.totalItemsEdit,
  getSelectElements: state => state.selectElements,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
